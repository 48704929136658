<script lang="ts">
	import { goto } from '$app/navigation';
	import Carousel from '$lib/components/Carousel.svelte';
	import type { Sources } from '$lib/types';
	export let data: Sources;

	const goToApp = () => {
		goto('/app');
	};
</script>

<landing>
	<header class="text-2xl lg:text-4xl space-y-7 font-semibold text-center">
		<p>Do you struggle to come up with placeholder text?</p>
		<p>Tired of using the same old boring Latin dummy text?</p>
		<p class="text-4xl lg:text-5xl py-6 lg:py-12 text-blue-300">Use Lorem Quotesum instead!</p>
		<div class="text-xl lg:text-2xl my-8 w-[80%] lg:w-[50%] mx-auto space-y-5">
			<p>
				Quickly find funny quotes from your favorite TV shows and movies and easily copy small or
				large selections into your app.
			</p>
			<Carousel images={data.sources} />
			<h1>Bring some humor back into your work! It's free!</h1>
			<p />
			<button
				class="btn variant-filled-primary text-xl lg:text-3xl"
				on:click={goToApp}
				on:keypress={goToApp}>Start Now</button
			>
			<p />
		</div>
	</header>
</landing>

<style>
	landing {
		/* filter: opacity(0.4); */
		display: block;
		width: 100dvw;
		height: 100%;
		background-image: url('/laptop-2443739_1920.jpg');
		background-size: cover;
	}
	header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		background-color: rgb(0 0 0 / 0.7);
		backdrop-filter: blur(4px);
		overflow-y: scroll;
	}
</style>
