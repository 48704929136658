<script lang="ts">
	import { onMount } from 'svelte';
	import { fade, slide, fly, crossfade } from 'svelte/transition';
	import { elasticInOut, quintOut } from 'svelte/easing';
	import type { Source } from '$lib/types';

	export let images: Source[] = [];

	const [send, receive] = crossfade({
		duration: 1500,
		easing: quintOut
	});

	let displayedIndices = [0, 1, 2, 3];
	const advance = () => {
		displayedIndices = displayedIndices.map((index) => (index + 1) % images.length);
	};
	let timer: NodeJS.Timer;

	const startInterval = () => {
		timer = setInterval(advance, 2000);
	};
	const stopInterval = () => {
		if (timer) clearInterval(timer);
	};

	onMount(() => {
		startInterval();
	});

	$: imagesToDisplay = displayedIndices.map((index) => images[index]);
</script>

<div class="carousel w-100%">
	{#each imagesToDisplay as image (image.image)}
		{#if image && image.image && image.label}
			<img
				in:send={{ key: image.image }}
				out:receive={{ key: image.image }}
				src={image.image}
				alt={image.label}
				style="width:200px; height:120px"
			/>
		{/if}
	{/each}
</div>

<style>
	.carousel {
		display: flex;
		flex-direction: row;
		justify-content: center;
		overflow: hidden;
		filter: drop-shadow(0px 0px 12px black);
	}
</style>
